@import "../imports/imports";

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

@each $i in $numbers {
  .order-#{$i} {
    order: $i !important;
  }
}


@each $key, $size in $grid-breakpoints {
  @include media($key) {

    .justify-content-#{$key}-between {
      justify-content: space-between !important;
    }

    .justify-content-#{$key}-around {
      justify-content: space-around !important;
    }

    .justify-content-#{$key}-center {
      justify-content: center !important;
    }

    .justify-content-#{$key}-start {
      justify-content: flex-start !important;
    }

    .justify-content-#{$key}-end {
      justify-content: flex-end !important;
    }

    .align-items-#{$key}-center {
      align-items: center !important;
    }

    .align-items-#{$key}-start {
      align-items: flex-start !important;
    }

    .align-items-#{$key}-stretch {
      align-items: stretch !important;
    }

    .align-items-#{$key}-end {
      align-items: flex-end !important;
    }

    .flex-#{$key}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-#{$key}-wrap {
      flex-wrap: wrap !important;
    }

    .flex-#{$key}-column {
      flex-direction: column !important;
    }

    .flex-#{$key}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-#{$key}-row {
      flex-direction: row !important;
    }

    .flex-#{$key}-row-reverse {
      flex-direction: row-reverse !important;
    }
  }
}
