@import "../imports/imports";

.pillar-page {

}

.content-body {
  .content-placeholder {
    :last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    @include font-size-h2();
    line-height: 110%;
    margin-bottom: 30px;
    margin-top: 0;
  }

  h2 {
    font-weight: $fw-regular;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 15px;

    a {
      color: $primary-color;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0;

    li {
      padding-bottom: 5px;

      p {
        display: inline;
      }
    }
  }

  ul {
    li {
      list-style: initial;
      list-style-position: inside;

      &::marker {
        color: $green-color;
      }
    }
  }

  ul, ol, p, img {
    margin-bottom: 20px;
  }
}

.pillar-pages-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
