@import "../imports/imports";

.footer-wrapper {
  background: url(($img-base + 'footer-bg.png')) $offlight-color center center repeat;
  border-top: 1px solid $grey-lightest-color;
  @include drop-shadow-inset();

  .pillar-pages-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  a:not([class]), .text-link {
    color: $text-color;
  }

  .upper-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 0 30px;

    @include media(md) {
      padding: 90px 0 60px;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    @include media(lg) {
      flex-wrap: nowrap;
    }

    .column {
      margin-top: 40px;
      order: 1;
      width: 100%;
      max-width: 450px;

      @include media(md) {
        max-width: 300px;
        width: 40%;
        margin: 40px auto 0;
        flex-grow: 1;
      }

      @include media(lg) {
        max-width: 400px;
        margin: 0;
      }

      &.column-2 {
        order: 0;

        @include media(md) {
          order: 2;
        }

        @include media(lg) {
          max-width: 450px;
        }
      }
    }

    h4, li, a, p {
      text-align: center;
      width: 100%;

      @include media(md) {
        text-align: left;
      }
    }

    .logo {
      width: 100%;
      margin: 0;
      box-sizing: content-box;
      font-size: 0;
      line-height: 0;
      min-width: 180px;

      @include media(lg) {
        position: relative;
        top: -15px;
        width: 180px;
        padding: 0 100px 0 0;
      }

      @include media(xl) {
        padding: 0 140px 0 50px;
      }

      a {
        text-align: center;

        @include media(lg) {
          text-align: left;
        }
      }

      img {
        display: inline-block;
      }
    }

    h4 {
      display: block;
      margin-bottom: 20px;
    }

    .input-text {
      min-width: 100%;

      @include media(xl) {
        min-width: 450px;
      }

      button {
        .fa-arrow-right {
          position: relative;
          left: 0;
          transition: left 0.25s $easeInBack;
        }

        &:hover {
          .fa-arrow-right {
            left: 7px;
            transition: left 0.3s $easeOutExpo;
          }
        }
      }
    }

    li {
      margin-bottom: 10px;

      a {
        display: block;
      }
    }

    p {
      a {
        display: inline;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .lower-section {
    padding: 40px 0;
    border-top: 1px solid $grey-lightest-color;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    @include media(md) {
      flex-direction: row;
    }

    p {
      &:nth-of-type(1) {
        margin: 10px 0 0;
      }

      @include media(lg) {
        margin: 0;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        &:after {
          content: "|";
          display: inline-block;
          padding: 0 10px;
        }

        &:last-of-type {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
