@import "../imports/imports";


.sidebar-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 30px 0;

  @include media(lg) {
    flex-direction: row;
    justify-content: space-between;
    padding: $sidebar-margin 0;
    overflow: hidden;
  }

  .content {
    @include media(lg) {
      flex-grow: 1;
      margin-right: $sidebar-gap;
    }
  }

  .sidebar {
    width: 100%;
    margin-top: 30px;

    @include media(lg) {
      width: $sidebar-width;
      min-width: $sidebar-width;
      margin-top: unset;

      .sticky-container {
        top: -$sidebar-margin;
        margin-top: $sidebar-margin;
        width: 100%;
      }
    }

    h4 {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 15px;
      font-size: $font-size-smaller;

      a {
        font-weight: $fw-bold;
        display: inline;
      }
    }

    .featured-articles, .more-articles {
      width: 100%;

      li {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .article-link {
          margin-bottom: 0;
        }
      }
    }

    .featured-articles {
      padding: 0;

      @include media(xxl) {
        width: 100%;
      }

      .article-link {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        background: $offlight-color;

        .img {
          min-width: none;
          width: auto;
          height: 150px;
          margin: -10px -10px 10px;

          @include media(xxl) {
            margin: -15px -15px 15px;
          }
        }
      }
    }

    .more-articles {
      margin: 40px 0;
      padding: 0;

      @include media(xxl) {
        width: 100%;
      }

      .articles-container {
        padding: 10px;
        background: $offlight-color;

        @include media(xxl) {
          padding: 15px;
        }
      }

      li {
        margin-bottom: 10px;

        .article-link {
          padding-bottom: 0;
        }
      }
    }

    .pillar-pages-list {
      flex-direction: column;
      margin: 0;
      padding-bottom: 0;

      @include media(lg) {
      }

      li {
        position: relative;
        z-index: 1;
        margin: 0 0 22px;
        width: 100%;
        background: $offlight-color;
        border-radius: $border-radius-large 0 0 $border-radius-large;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 6px;
          background: $primary-color;
          transition: width 0.2s $easeInOutExpo, border-radius 0.1s;
          z-index: 1;
        }


        .inner-template {
          display: flex;
        }

        a {
          text-decoration: none;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          transition: color 0.13s 0.08s;
          padding: 10px;
          position: relative;
          z-index: 2;

          @include media(lg) {
            padding: 10px 30px 10px;
          }

          $icon-size-sm: 30px;
          $icon-size-lg: 40px;

          .icon {
            min-width: $icon-size-sm;
            width: $icon-size-sm;
            height: $icon-size-sm;
            background: $primary-color;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-right: 10px;
            transition: background-color 0.1s 0.06s;

            img {
              width: $icon-size-sm - 15;
            }

            @include media(lg) {
              min-width: $icon-size-lg;
              width: $icon-size-lg;
              height: $icon-size-lg;
              margin-right: 20px;

              img {
                width: $icon-size-lg - 15;
              }
            }
          }

          p {
            text-transform: uppercase;
            font-weight: $fw-bold;
            line-height: 110%;
          }
        }


        &.green {
          &:after {
            background-color: $green-color;
          }

          .icon {
            background-color: $green-color;
          }
        }

        &.pink {
          &:after {
            background-color: $pink-color;
          }

          .icon {
            background-color: $pink-color;
          }
        }

        &.red {
          &:after {
            background-color: $red-color;
          }

          .icon {
            background-color: $red-color;
          }
        }

        &.active {
          display: none;
          margin: 0;
          padding: 0;
          visibility: collapse;
        }

        &:hover {
          a {
            color: $light-color;
            transition: color 0.1s 0.12s;

            .icon {
              background: $light-color;
              box-shadow: 0 0 25px 1px rgba(255,255,255,0.15);
              transition: background-color 0.17s 0.13s, box-shadow 0.2s 0.16s;
            }
          }

          &:after {
            width: 100%;
            border-radius: $border-radius-large 0 0 $border-radius-large;
            transition: width 0.3s $easeInOutExpo, border-radius 0.1s 0.2s;
          }
        }
      }
    }

    .banner-wrapper {
      img {
        margin-top: 40px;
      }
    }
  }
}
