@import "../imports/imports";

.main-content-wrapper {
  position: relative;
  z-index: $z-base;

  .menu-is-open & {

    @include media(xl) {
    }
  }
}

.header {
  background: $light-color;
  display: block;
  position: relative;
  transition: height 0.2s, background 0.3s;
  height: $header-height-xs;
  @include drop-shadow();

  @include media(md) {
    transition: background 0.3s;
    height: auto;
  }

  .container {
    padding: 0;

    @include media(xl) {
      width: 94%;
      max-width: 100%;
    }
  }
  //&.fixed {
  //  height: $header-height;
  //  z-index: $z-header-fixed;
  //  background: $light-color;
  //  position: fixed;
  //  left: 0;
  //  right: 0;
  //  top: 0;
  //  width: 100%;
  //  padding-right: $scrollbar-width;
  //
  //  @include media(xl) {
  //    height: $header-height-xl;
  //    padding-right: 0;
  //  }
  //
  //  .container {
  //  }
  //
  //  .inner {
  //  }
  //}
  .menu-is-open & {
    background: $menu-bg-color;
    height: 100vh;

    @include media(md) {
      height: auto;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    padding: 10px 0;

    @include media(xl) {
      padding: 15px 0;
    }
  }

  .logo {
    text-align: center;
    font-size: 2rem;
    z-index: $z-header-buttons;
    margin: 0 auto 0 0;
    padding: 0;

    a {
      display: block;

      img {
        width: 136px;
        min-width: 136px;
        padding-top: 4px;

        @include media(xl) {
          width: 180px;
          min-width: 180px;
        }
      }
    }
  }

  $btn-height-sm: 50px;
  $btn-height-xl: 60px;

  .search-button, .menu-button, .close-button, .gsc-results-close-btn {
    height: $btn-height-sm;
    width: $btn-height-sm;
    min-width: $btn-height-sm;
    max-width: $btn-height-sm;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    color: $grey-dark-color;
    background: $light-color;
    transition: background 0.2s, color 0.1s;
    position: relative;
    outline: none !important;

    @include media(xl) {
      height: $btn-height-xl;
      width: $btn-height-xl;
      min-width: $btn-height-xl;
      max-width: $btn-height-xl;
    }

    &:before {
      content: "\f002";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 20px;
      color: inherit;
      line-height: $btn-height-sm;
      width: 100%;
      text-align: center;
      text-decoration: none !important;

      @include media(xl) {
        font-size: 25px;
        line-height: $btn-height-xl;
      }
    }

    &:hover {
      background: $offlight-color;
    }

    &.close-button, &.gsc-results-close-btn {
      background: $primary-color;
      color: $light-color;
      z-index: $z-header-buttons;

      &:before {
        content: "\f00d";
      }
    }

    &.search-button {
      margin-left: auto;

      @include media(lg) {
        display: none;
      }
    }

    &.menu-button {
      margin-left: 10px;

      &:before {
        content: "\f0c9";
      }

      .menu-is-open & {
        &:before {
          content: "\f00d";
        }
      }
    }
  }

  .search-bar {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparentize($light-color, .02);
    z-index: $z-search-bar;

    &.show {
      display: flex;
      overflow: auto;
      top: $header-height-xs;

      @include media(xl) {
        top: $header-height-xl;
      }

      @include media(xxl) {
        top: 62px;
      }

      app-pillar-pages-list {
        display: block;
      }
    }

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;

      @include media(xl) {
        display: none;
      }
    }
  }

  .search-bar {
    @include media(lg) {
      background: none;
      position: relative;
      display: block;
      top: 0;
      padding: 0;
      flex-grow: 1;
    }

    &.show {
      display: block;
      padding: 20px 18px 60px;

      @include media-between(xl, xxl) {
        top: $header-height-xl;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  $results-wrapper-top: 140px;
  $results-wrapper-top-xl: 165px;

  .gsc-results-wrapper-overlay {
    box-shadow: none;
    background: none;
    width: 100%;
    height: calc(100% - #{$results-wrapper-top});
    top: $results-wrapper-top;
    left: 0;
    padding: 20px;

    .gsc-results-close-btn {
      display: none !important;
    }

    @include media(xl) {
      padding: 20px 0;
      border-radius: 0;
      height: calc(100vh - #{$results-wrapper-top-xl});
      top: $results-wrapper-top-xl;
      width: 100%;
      left: 0;
    }

    @include media(xxl) {
      padding: 20px;
      height: calc(100vh - #{$header-height-xl});
      top: $header-height-xl;
    }
  }

  .gsc-above-wrapper-area,
  .gsc-positioningWrapper,
  .gsc-adBlockInvisible,
  .gsc-control-cse,
  .gsc-wrapper {
    @include media(xl) {
      width: $container-width-md;
      max-width: $container-max-width;
      margin: 0 auto;
    }
  }

  .gsc-modal-background-image-visible {
    display: none;

    @include media(xxl) {
      display: block;
      background: $light-color;
      box-shadow: 0 0 6px transparentize($dark-color, 0.6);
      height: calc(100vh - #{$header-height-xl});
      top: $header-height-xl;
      left: 0;
      opacity: 1;
    }
  }

  .gsc-control-cse {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    position: relative;

    table.gsc-search-box {
      margin: 0;
    }

    form.gsc-search-box {
      display: flex;
      align-items: center;
      margin: 0;
      height: 45px;
      padding: 5px;
      border-radius: 25px;
      min-width: 200px;
      position: relative;
      border: 2px solid $grey-light-color;
      background: $light-color;

      td {
        padding: 0;
      }

      .gsib_b {
        display: flex;
        height: 26px;
        align-items: center;
        width: 30px;

        .gsst_a {
          background: $light-color;
          text-decoration: none !important;

          .gscb_a {
            display: none;
          }

          &:before {
            content: "\f00d";
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            color: $primary-color;
            line-height: 26px;
            width: 100%;
            text-align: center;
            text-decoration: none !important;
          }
        }
      }

      .gsst_b {
        line-height: 25px;
      }

      .gsc-input {
        padding: 0 0 0 12px;
        background: none !important;
        width: 100%;
      }

      .gsc-input-box {
        border: none;
        padding: 0;
        background: none;
      }

      .gsc-search-button {
        display: flex;
        cursor: pointer;
        background: none;
        border: none;
        position: relative;
        width: 30px;

        button {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          padding: 0;

          svg {
            display: none;
            opacity: 0;
          }
        }

        &:before {
          content: "\f002";
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          color: $primary-color;
          line-height: 26px;
          width: 100%;
          text-align: center;
        }
      }
    }

    .gsc-results {
      .gsc-result {
        padding: 20px 0;

        .gsc-url-top {
          line-height: 12px;
        }

        .gsc-table-result {
          line-height: 18px;
        }
      }
    }
  }
}
