$linear        : cubic-bezier(0.250, 0.250, 0.750, 0.750) !default;
$ease          : cubic-bezier(0.250, 0.100, 0.250, 1.000) !default;
$easeIn        : ease-in;
$easeOut       : ease-out;
$easeInOut     : ease-in-out;
$ease-in       : cubic-bezier(0.420, 0.000, 1.000, 1.000) !default;
$ease-out      : cubic-bezier(0.000, 0.000, 0.580, 1.000) !default;
$ease-in-out   : cubic-bezier(0.420, 0.000, 0.580, 1.000) !default;

$easeInQuad    : cubic-bezier(0.550, 0.085, 0.680, 0.530) !default;
$easeInCubic   : cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$easeInQuart   : cubic-bezier(0.895, 0.030, 0.685, 0.220) !default;
$easeInQuint   : cubic-bezier(0.755, 0.050, 0.855, 0.060) !default;
$easeInSine    : cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;
$easeInExpo    : cubic-bezier(0.950, 0.050, 0.795, 0.035) !default;
$easeInCirc    : cubic-bezier(0.600, 0.040, 0.980, 0.335) !default;
$easeInBack    : cubic-bezier(0.18, 0.89, 0.32, 1.28) !default;

$easeOutQuad   : cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;
$easeOutCubic  : cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$easeOutQuart  : cubic-bezier(0.165, 0.840, 0.440, 1.000) !default;
$easeOutQuint  : cubic-bezier(0.230, 1.000, 0.320, 1.000) !default;
$easeOutSine   : cubic-bezier(0.390, 0.575, 0.565, 1.000) !default;
$easeOutExpo   : cubic-bezier(0.190, 1.000, 0.220, 1.000) !default;
$easeOutCirc   : cubic-bezier(0.075, 0.820, 0.165, 1.000) !default;
$easeOutBack   : cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

$easeInOutQuad : cubic-bezier(0.455, 0.030, 0.515, 0.955) !default;
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000) !default;
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000) !default;
$easeInOutSine : cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$easeInOutExpo : cubic-bezier(1.000, 0.000, 0.000, 1.000) !default;
$easeInOutCirc : cubic-bezier(0.785, 0.135, 0.150, 0.860) !default;
$easeInOutBack : cubic-bezier(0.680,-0.550, 0.265, 1.550) !default;

/*Transitions*/
$trans-speed-fast: 0.1s;
$trans-speed-medium: 0.2s;
$trans-speed-slow: 0.6s;
$trans-fast: all $trans-speed-fast $easeIn;
$trans-medium: all $trans-speed-medium $easeOutExpo;
$trans-slow: all $trans-speed-slow $easeOutExpo;
$trans-default: $trans-medium;
$default-animation: 0.4s $easeOutExpo;
