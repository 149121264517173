@import "../imports/imports";

.z-index-10 {
  position: relative;
  z-index: 10 !important;
}

.z-index-100 {
  position: relative;
  z-index: 100 !important;
}

