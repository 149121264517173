@import "../imports/imports";

.breadcrumbs-wrapper {
  background: url(($img-base + 'breadcrumbs-bg.png')) repeat center 4px $primary-color;
  padding: 15px 0;
  color: $light-color;
  position: relative;
  z-index: $z-base;

  @include media(lg) {
    padding: 30px 0;
  }

  .inner {
    @include media(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      display: none;

      @include media(lg) {
        display: block;
        padding: 0;
        margin: 0 20px 0 0 !important;
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  @include ellipsis();

  @include media(lg) {
    flex-wrap: nowrap;
  }

  li {
    padding-right: 5px;
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 65px;
    @include ellipsis();

    a, span {
      color: $light-color;
      padding-right: 5px;
      display: inline-block;
      text-transform: uppercase;
      @include ellipsis();
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &:after {
      content: ">";
    }

    &:last-of-type {
      padding-right: 0;

      &:after {
        content: none;
      }

      a, span {
        padding-right: 0;
      }
    }
  }
}
