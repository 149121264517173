@import "../imports/imports";

html {
  overflow-y: scroll !important;
}

html, body {
  min-height: 100%;
  min-height: 100vh;

  @include media(md) {
    height: 100%;
  }
}

html:after {
  @include media(xxl) {
    content: "";
    display: block;
    z-index: -1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

body {
  margin: 0;
  font-family: $font-family-body;
  overflow: initial;

  &.gsc-overflow-hidden:not(.menu-is-open) {
    overflow: initial;
  }

  &.menu-is-open {
    @include media-below(md) {
      overflow: hidden;
      position: fixed;
      width: 100%;
    }

    scrollbar-width: $scrollbar-width;
    scrollbar-color: $light-color $light-color;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background: $light-color;
    }
  }

  &, * {
    box-sizing: border-box;
    scrollbar-width: $scrollbar-width;
    scrollbar-color: $primary-color #f0f0f0;
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
      background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 8px 0 0 8px;
      border: 2px solid #f0f0f0;
      border-width: 2px 0 2px 1px;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }
  }

  :after, :before {
    box-sizing: border-box;
  }
}

img {
  max-width: 100%;
  display: block;

  &.full-img {
    max-width: 100% !important;
  }

  &.hidden-img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.container {
  @include container();
}

.container-fluid {
  width: 100%;
  @include container-fluid-padding();
}

.inner-content {
  display: block;
  width: 100%;
  padding: ($main-padding-m*2) $main-padding-m;
  max-width: map-get($grid-breakpoints, lg);
  margin: 0 auto;
  overflow: visible;

  @include media(sm) {
    padding: 40px;
  }

  &.xs {
    max-width: 400px;
  }

  &.sm {
    max-width: map-get($grid-breakpoints, sm);
  }

  &.md {
    max-width: map-get($grid-breakpoints, md);
  }

  &.lg {
    max-width: 100%;
  }

  &.mat-divider {
    padding: 0;
  }
}

layout-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;

  app-footer {
    margin-bottom: 0;
    margin-top: auto;
    position: relative;
    z-index: 3;
  }
}

@include color-scheme(background, rounded-box-) {
  border-radius: $border-radius-large;
  padding: 30px 20px;
}


.section {
  &.image-right-40 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .text {
      width: 100%;
      padding-bottom: 20px;
    }

    .image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    @include media(md) {
      flex-direction: row;
      justify-content: space-between;

      .text {
        max-width: 60%;
        min-width: 60%;
        padding-right: 20px;
        padding-bottom: 0;
      }

      .image {
        max-width: 40%;
        min-width: 40%;
        justify-content: flex-end;
      }
    }

    @include media(lg) {
      flex-direction: column;
      justify-content: flex-start;

      .text {
        max-width: none;
        min-width: 0;
        padding-right: 0;
        padding-bottom: 20px;
      }

      .image {
        max-width: none;
        min-width: 0;
        justify-content: flex-start;
      }
    }

    @include media(xl) {
      flex-direction: row;
      justify-content: space-between;

      .text {
        max-width: 60%;
        min-width: 60%;
        padding-right: 20px;
        padding-bottom: 0;
      }

      .image {
        max-width: 40%;
        min-width: 40%;
        justify-content: flex-end;
      }
    }
  }
}

hr {
  border: none;
  border-top: $default-border $grey-light-color;
}

* {
  image-rendering: -webkit-optimize-contrast;
}

/*Elements defaults*/
table {
  width: 100%;
}

.selected {
  background-color: $primary-color;
  color: $light-color;
  transition: background-color 0.2s, padding 0.2s;

  &.box {
    padding: 20px;
    margin: 10px 0;
  }

  &.light {
    background-color: $grey-color;
    color: $dark-color;
  }
}

ul {
  padding: 0;

  &:not(.bullet-list) {
    margin: 0;

    li {
      list-style: none;
    }
  }

  &.bullet-list {
    li {
      list-style: disc outside;
      display: list-item !important;
      margin: 0 0 5px 22px;
    }
  }
}

.scroll-top-button {
  position: fixed;
  bottom: 40px;
  right: 20px;
  background: $primary-color;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.2);
  z-index: $z-header-fixed-after;
  cursor: pointer;
  text-align: center;
  color: $light-color;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.2);

    .mat-icon {
      top: -10px;
      transition: top 0.6s $easeOutExpo;
    }
  }

  .mat-icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    display: inline-block;
    top: 0;
    position: relative;
    transition: top 0.15s;
  }
}
