@import 'variables';

/***************************************************** Updated for this project *****************************************************/
$purple-color: #4E1AC8;
$green-color: #7DC827;
$pink-color: #AE00E2;
$red-color: #F17458;

$primary-color: $purple-color;
$secondary-color: $green-color;
$tertiary-color: $pink-color;
$quaternary-color: $red-color;

$warn-color: $red-color;
$success-color: $green-color;
$accent-color: $pink-color;
$info-color: $purple-color;

$dark-color: #454545;
$text-color: $dark-color;

$grey-dark-color: #707070;
$grey-medium-color: #989595;
$grey-color: #cccccc;
$grey-light-color: #DBDBDB;
$grey-lightest-color: #E0E0E0;

$offlight-color: #F8F8F8;
$light-color: #ffffff;

$color-map: (
/*     purple: $purple-color,
        green: $green-color,
         pink: $pink-color,
          red: $red-color,
*/
      primary: $primary-color,
    secondary: $secondary-color,
/*   tertiary: $tertiary-color,
   quaternary: $quaternary-color,*/

         warn: $warn-color,
      success: $success-color,
       accent: $accent-color,
         info: $info-color,

        light: $light-color,
     offlight: $offlight-color,
grey-lightest: $grey-lightest-color,
   grey-light: $grey-light-color,
/*       grey: $grey-color,
    grey-dark: $grey-dark-color,*/
         dark: $dark-color
);


$card-color: #fff;
$card-border-color: $grey-color;
$menu-bg-color: #F1EDFB;
$menu-text-color: $light-color;
$page-color: $light-color;
$input-border-color: transparentize($grey-color, 0.5);

$dialog-background-dark: transparentize(darken($secondary-color, 20), 0.2);
$dialog-background-light: transparentize($light-color, 0.2);
$table-header-bg: transparentize($grey-lightest-color, 0.6);


/***************************************************** Imported*****************************************************/
