@import "../imports/imports";

body {
  .mat-menu-trigger {
    border: none;
    outline: none;
    cursor: pointer;
    line-height: 24px;
    padding: 20px;
    border-radius: $default-border-radius;
    background: transparent;
    transition: background 0.3s;

    &:hover {
      transition: background 0.1s;
      background: $offlight-color;
    }

    &.expanded {
      background: $offlight-color;
    }

    @include media(lg) {
      padding: 10px 15px;
    }
  }

  .mat-menu-panel {
    $arrow-size: 6px;
    padding-top: $arrow-size;
    margin-top: -($arrow-size + 2);

    &.mat-menu-above, &.mat-menu-below {
      overflow: visible;

      .mat-menu-content {
        max-height: 100%;
        overflow: auto;

        &:after {
          content: "";
          position: absolute;
          left: 20px;
          box-shadow: 0 0 3px transparentize($dark-color, 0.9);
          transform: rotate(45deg);
          width: (2 * $arrow-size);
          height: (2 * $arrow-size);
          border: 1px solid $grey-lightest-color;
          background: $light-color;
          z-index: 1;
        }
      }
    }

    &.mat-menu-below {
      padding-top: $arrow-size;

      .mat-menu-content {
        &:after {
          top: -$arrow-size;
        }
      }
    }

    &.mat-menu-above {
      padding-bottom: $arrow-size;

      .mat-menu-content {
        &:after {
          bottom: -$arrow-size;
        }
      }
    }

    .mat-menu-content {
      border: 1px solid $grey-lightest-color;
      border-radius: $default-border-radius;
      background: $light-color;
      box-shadow: 0 0 5px transparentize($dark-color, 0.9);

      &:not(:empty) {
        padding: 0;
      }
    }

    .mat-menu-item {
      border-top: 1px solid $grey-lightest-color;
      background: $light-color;
      transition: background 0.3s;
      position: relative;
      z-index: 2;

      &:first-of-type {
        border-top: none;
        // Don't need this anymore as the `.mat-menu-content` has `overflow: auto;` set
        //border-radius: $default-border-radius $default-border-radius 0 0;
      }

      &.view-all {
        border-top: 1px solid $grey-lightest-color;
      }

      &:last-of-type {
        //border-radius: 0 0 $default-border-radius $default-border-radius;
      }

      &:hover {
        transition: background 0.1s;
        background: $offlight-color;
      }
    }
  }
}
