@import "../imports/imports";

.mega-menu-component {
  position: fixed;
  top: $header-height-xs;
  left: 0;
  width: 100%;
  height: 0;
  z-index: $z-header-fixed + 1;
  display: block;
  transition: height 0.3s $easeOutExpo;
  overflow: hidden;

  @include media(md) {
    position: relative;
    max-height: 0;
    transition: max-height 0.3s $easeOutExpo;
    pointer-events: none;
  }

  @include media(xl) {
  }

  &.show {
    transition: height 0.2s $easeInExpo;
    height: calc(100vh - #{$header-height-xs});

    @include media(md) {
      height: auto;
      max-height: 2000px;
      transition: max-height 0.3s $easeInExpo;
    }

    .mega-menu {
      .inner {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.mega-menu-container {
  pointer-events: all;
  position: relative;
}

.mega-menu {
  padding: 0 0 20px;

  @include media(xl) {
    padding: 0 0 60px;
  }

  .inner {
    display: flex;
    align-items: stretch;
    @include container;
    max-width: 1900px;
    transform-origin: bottom center;
    opacity: 0;
    transition: opacity 0.15s 0.2s;
    width: 100%;

    @include media(md) {
      width: $container-width-md;
      transform: scale(0.8);
      transition: transform 0.3s 0.15s, opacity 0.2s 0.1s;
    }

    @include media(lg) {
      padding: 0 0 60px;
    }
  }

  .content {
    @include media-below(md) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: $menu-bg-color;
      z-index: 2;
      transform: translateX(100%);
      transition: transform 0.2s;
      overflow: hidden;

      .show & {
        height: calc(100vh - #{$header-height-xs});
        overflow: auto;
        padding: 0 0 20px;
      }

      .pillar-selected & {
        transform: translateX(0);
      }
    }

    .back-arrow {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: $fw-bold;
      margin-right: auto;
      color: $light-color;
      white-space: nowrap;
      margin-right: 20px;

      @include media(md) {
        display: none;
        margin-right: auto;
      }

      .mat-icon {
        margin-right: 5px;
      }
    }

    @include media(md) {
      width: 70%;
      position: relative;
      left: auto;
    }

    .inner-content {
      padding: 0 0 40px;
      background: $light-color;
      max-width: none;

      @include media(md) {
        padding: 0;
        border-radius: 0 10px 10px 10px;
        min-height: 770px;
      }
    }

    .article-lists {
      padding: 0 15px;

      @include media(md) {
        padding: 0 20px 20px;
      }

      @include media(xl) {
        display: flex;
        align-items: flex-start;
        width: 100%;
      }

      .featured-articles {
        padding: 0;

        @include media(xl) {
          width: 60%;
          padding: 0 40px 0 0;
        }

        @include media(xxl) {
          width: 100%;
        }
      }

      .more-articles {
        background: $grey-lightest-color;

        @include media(xl) {
          width: 40%;
        }
      }
    }

    .pillar-summary {
      text-align: left;
      margin: 0 0 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      @include media(md) {
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 30px;
        padding: 30px 35px;
        border-bottom: 1px solid $grey-lightest-color;
        cursor: unset;
        background: none !important;
      }

      .icon {
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        padding: 6px;
        border-radius: 50%;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media(md) {
          margin-right: 20px;
          margin-left: 0;
          min-width: 109px;
          max-width: 109px;
          height: 109px;
          padding: 10px;
        }

        @include media-below(md) {
          background: $light-color !important;
          order: 3;
        }
      }

      h3 {
        padding: 0;
        margin: 0;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $light-color;

        @include media(md) {
          color: $dark-color;
          margin: 0 0 7px;
        }

        &:before, &:after {
          content: none;
          display: none;
        }
      }

      .btn {
        margin: 10px 0 0;
        max-width: 180px;
        display: block;
        font-size: $font-size-smaller;
        padding: 10px 15px;
      }

      .summary {
        font-size: $font-size-tiny;

        p, .btn {
          display: none;

          @include media(md) {
            display: block;
          }
        }

        @include media-below(md) {
          width: 100%;
          margin-left: auto;

          h3 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            text-align: right;
          }
        }

        @include media-below(sm) {
          max-width: 50%;
        }

        @include media-below(xs) {
          display: none;
        }
      }

      &.purple {
        .icon, .btn, & {
          background-color: #4E1AC8;
        }
      }

      &.green {
        .icon, .btn, & {
          background-color: #7DC827;
        }
      }

      &.pink {
        .icon, .btn, & {
          background-color: #AE00E2;
        }
      }

      &.red {
        .icon, .btn, & {
          background-color: #F17458;
        }
      }
    }

    .current-pillar-header {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;

      .icon {
        max-width: 50px;
      }
    }
  }

  .pillar-list-layout {
    @include media-below(md) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: $menu-bg-color;
      z-index: 1;
      overflow: hidden;
      transform: translateX(0);
      transition: transform 0.2s;

      .show & {
        height: calc(100vh - #{$header-height-xs});
        overflow: auto;
      }

      .pillar-selected & {
        transform: translateX(-100%);
      }
    }

    @include media(md) {
      width: 30%;
      max-width: 400px;
    }

    @include media(xxl) {
      min-width: 400px;
    }

    .pillar-pages-list {
      padding-bottom: 0;
      overflow: hidden;

      @include media(md) {
        border-radius: $border-radius-large 0 0 $border-radius-large;
      }

      li {
        margin-bottom: 1px;
        border-radius: 0;

        &:before {
          content: "arrow_forward";
          position: absolute;
          right: 30px;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 24px;

          @include media(md) {
            content: none;
          }
        }

        &:hover, &.active {
          &:after {
            border-radius: 0;
          }
        }

        &.purple {
          .icon {
            background-color: $purple-color;
          }

          &:before {
            color: $purple-color;
          }
        }

        &.green {
          .icon {
            background-color: $green-color;
          }

          &:before {
            color: $green-color;
          }
        }

        &.pink {
          .icon {
            background-color: $pink-color;
          }

          &:before {
            color: $pink-color;
          }
        }

        &.red {
          .icon {
            background-color: $red-color;
          }

          &:before {
            color: $red-color;
          }
        }


        &.active {
          @include media-below(md) {
            a {
              color: $text-color;
            }

            &:after {
              width: 6px;
              max-width: 6px;
            }
          }
        }

        .inner-template {
          cursor: pointer;
          padding: 20px 30px 20px 15px;

          $icon-size-sm: 45px;
          $icon-size-lg: 65px;

          .icon {
            min-width: $icon-size-sm;
            width: $icon-size-sm;
            height: $icon-size-sm;
            margin-right: 20px;

            img {
              width: $icon-size-sm - 20;
            }

            @include media(lg) {
              min-width: $icon-size-lg;
              width: $icon-size-lg;
              height: $icon-size-lg;

              img {
                width: $icon-size-lg - 28;
              }
            }
          }

          p {
          }
        }
      }
    }
  }

  .static-links {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;

    li {
      padding: 0 20px;
      border-left: 1px solid $text-color;
      border-right: 1px solid $text-color;

      &:last-of-type {
        border-left: 1px solid $text-color;
        border-right: none;
      }

      &:first-of-type {
        border-left: none;
        border-right: 1px solid $text-color;
      }

      a {
        display: block;
        padding: 2px 5px;
        text-transform: uppercase;
        font-size: $font-size-smaller;
        font-weight: $fw-bold;
        color: $text-color;
      }
    }
  }
}
