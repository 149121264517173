@import "../../imports/imports";

.btn {
  border-radius: $button-border-radius;
  background: $primary-color;
  padding: 15px 30px;
  line-height: 27px;
  font: inherit;
  border: none;
  color: $light-color;
  font-weight: $fw-bold;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 180px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }

  &.sm {
    min-width: 80px;
  }

  &.lg {
    line-height: 43px;
  }

  @include color-scheme-embedded('background-color', 'btn-');

  &.btn-round {
    width: 57px;
    min-width: 57px;
    height: 57px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled, &:disabled {
    pointer-events: none !important;
  }
}
