@import "../imports/imports";

$pointer-opts: ( none: none, all: all);
$cursor-opts: ( pointer: pointer, text: text);

@each $name, $opt in $cursor-opts {
  .cursor-#{$name} {
    cursor: $opt !important;
  }
}

@each $name, $opt in $pointer-opts {
  .pointer-events-#{$name} {
    pointer-events: $opt !important;
  }
}
