/*Paths*/
$img-base: "/assets/img/";

/*Fonts*/
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Courier New, Courier, monospace;

$font-family-body: $font-family-sans-serif;
$font-family-heading: $font-family-sans-serif;

$font-size-tiny: 0.75rem;
$font-size-smaller: 0.875rem;
$font-size-small: 0.9rem;
$font-size-regular: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 2rem;
$font-size-oversized: 3rem;

$font-size-h1: 42px;
$font-size-h1-sm: 42px;
$font-size-h1-md: 56px;
$font-size-h1-lg: 62px;
$font-size-h1-xl: 68px;

$font-size-h2: 26px;
$font-size-h2-sm: 26px;
$font-size-h2-md: 34px;
$font-size-h2-lg: 38px;
$font-size-h2-xl: 42px;


$font-size-h3: 16px;
$font-size-h3-sm: 16px;
$font-size-h3-md: 18px;
$font-size-h3-lg: 22px;
$font-size-h3-xl: 26px;

$font-size-h4: 16px;
$font-size-h5: 1.125rem;


/*Font weights*/
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;
$fw-black: 900;
$text-transform: uppercase;

/*Defaults*/
$default-border: 1px solid;
$default-border-radius: 8px;
$border-radius-large: 10px;
$button-border-radius: 5px;

/*Responsive*/
$grid-breakpoints-full: (xs: 420px, sm: 576px, md: 768px, lg: 960px, xl: 1280px, xlm: 1400px, xxl: 1600px, xxxl: 2000px);
$grid-breakpoints: (sm: 576px, md: 768px, lg: 960px, xl: 1280px);

/*Menu defaults*/
$menu-width-m: 80px;
$menu-width: 90px;

$menu-padding-horz-m: 20px;
$menu-padding-vert-m: .5rem;
$menu-padding-horz: 3.125rem;
$menu-padding-vert: 1.5rem;

$dialog-header-height-m: 60px;
$dialog-header-height: 100px;
$dialog-tab-height-m: 40px;
$dialog-tab-height: 50px;
$dialog-tab-icon-width: 20px;
$dialog-logo-width: 100px;
$dialog-logo-height: calc(195px / 3);

$footer-height-md: 440px;
$footer-height-lg: 320px;
$footer-height-xl: 390px;
$footer-height-xxl: 460px;

$container-width: 90%;
$container-width-md: 84%;
$container-max-width: 1280px;

$sidebar-gap: 80px;
$sidebar-margin: 60px;
$sidebar-width: 300px;

$action-menu-width: 27px;
$mat-corner-radius: 4px;
$card-corner-radius: 10px;
$card-vert-padding: 1.5rem;
$card-vert-padding-sm: 2rem;
$card-horz-padding: 1rem;
$card-horz-padding-sm: 1.5rem;
$card-horz-padding-md: 1.5rem;
$card-horz-padding-lg: 2rem;
$card-horz-padding-xl: 2.5rem;
$card-horz-wide-padding: 1.2rem;
$box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;

$main-padding-m: 10px;
$main-padding: 15px;

$header-height-xs: 70px;
$header-height-xl: 90px;

$scrollbar-width: 8px;

$button-corner-radius: 4px;

// Util helpers
$numbers: (0,1,2);
$opacity-opts: ( 100: 1, 0: 0);
$widths: ( 100: 100%, 50: 50%, 0: 0%, auto: auto);


// Elevation
$z-bottom: -1;
$z-base: 1;
$z-2: $z-base + 1;
$z-header-fixed: 100;
$z-search-bar: 1000;
$z-main-nav: 1001;
$z-header-buttons: 1001;
$z-header-fixed-after: 1003;
$z-header-fixed: 100;
$z-header-fixed: 100;
