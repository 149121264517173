@import "../imports/imports";

[class*="w-"], [class*="h-"] {
  box-sizing: border-box;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100% !important;
}

@each $name, $width in $widths {
  .w-#{$name} {
    width: $width !important;
  }

  .max-width-#{$name} {
    max-width: $width !important;
  }
}


@each $key, $size in $grid-breakpoints {
  @include media($key) {
    
    @each $name, $width in $widths {
      .w-#{$key}-#{$name} {
        width: $width !important;
      }

      .max-width-#{$key}-#{$name} {
        max-width: $width !important;
      }
    }
  }
}
