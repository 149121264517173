@import "../imports/imports";

$sides: ((t, top), (l, left), (r, right), (b, bottom));

/*Utils*/
.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@each $alias, $side in $sides {
  .m#{$alias}-auto {
    margin-#{$side}: auto !important;
  }
}

@each $i in $numbers {

  .order-#{$i} {
    order: $i !important;
  }

  .m-#{$i} {
    margin: $main-padding*$i !important;
  }

  .mx-#{$i} {
    margin-left: $main-padding*$i !important;
    margin-right: $main-padding*$i !important;
  }

  .my-#{$i} {
    margin-top: $main-padding*$i !important;
    margin-bottom: $main-padding*$i !important;
  }

  .p-#{$i} {
    padding: $main-padding*$i !important;
  }

  .px-#{$i} {
    padding-left: $main-padding*$i !important;
    padding-right: $main-padding*$i !important;
  }

  .py-#{$i} {
    padding-top: $main-padding*$i !important;
    padding-bottom: $main-padding*$i !important;
  }

  @each $alias, $side in $sides {
    .m#{$alias}-#{$i} {
      margin-#{$side}: $main-padding*$i !important;
    }

    .p#{$alias}-#{$i} {
      padding-#{$side}: $main-padding*$i !important;
    }
  }
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    .mx-#{$key}-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    @each $alias, $side in $sides {
      .m#{$alias}-#{$key}-auto {
        margin-#{$side}: auto !important;
      }
    }

    @each $i in $numbers {
      // Order
      .order-#{$key}-#{$i} {
        order: $i !important;
      }
      // Margin
      .m-#{$key}-#{$i} {
        margin: $main-padding*$i !important;
      }

      .mx-#{$key}-#{$i} {
        margin-left: $main-padding*$i !important;
        margin-right: $main-padding*$i !important;
      }

      .my-#{$key}-#{$i} {
        margin-top: $main-padding*$i !important;
        margin-bottom: $main-padding*$i !important;
      }
      // Padding
      .p-#{$key}-#{$i} {
        padding: $main-padding*$i !important;
      }

      .px-#{$key}-#{$i} {
        padding-left: $main-padding*$i !important;
        padding-right: $main-padding*$i !important;
      }

      .py-#{$key}-#{$i} {
        padding-top: $main-padding*$i !important;
        padding-bottom: $main-padding*$i !important;
      }


      @each $alias, $side in $sides {
        .m#{$alias}-#{$key}-#{$i} {
          margin-#{$side}: $main-padding*$i !important;
        }

        .p#{$alias}-#{$key}-#{$i} {
          padding-#{$side}: $main-padding*$i !important;
        }
      }
    }
  }
}
