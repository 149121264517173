@import "../../imports/imports";

.input-text {
  background: $light-color;
  padding: 5px 5px 5px 20px;
  border: 1px solid $grey-light-color;
  border-radius: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    color: $text-color;
    font-weight: $fw-regular;
    font-size: 1rem;
    flex-grow: 1;
    line-height: 57px;
    outline: none !important;
    min-width: 0;
  }

  &.disabled, &:disabled {
    pointer-events: none !important;
    background: $offlight-color;

    input {
      color: $grey-color;
    }

    .btn {
        background-color: $grey-color !important;
    }
  }
}

.input-message {
  font-size: 0.75rem;
  display: block;
  padding: 5px 70px 0px 20px;
}
