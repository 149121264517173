@import "../../imports/imports";


.pillar-list-layout {
  width: 100%;

  .pillar-pages-list {
    flex-direction: column;
    margin: 0;
    padding-bottom: 20px;

    @include media(lg) {
    }

    li {
      position: relative;
      z-index: 1;
      margin: 0 0 22px;
      width: 100%;
      background: $offlight-color;
      border-radius: $border-radius-large 0 0 $border-radius-large;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 6px;
        background: $primary-color;
        transition: width 0.2s $easeInOutExpo, border-radius 0.1s;
        z-index: 1;
      }


      .inner-template {
        display: flex;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        transition: color 0.13s 0.08s;
        padding: 15px 10px;
        position: relative;
        z-index: 2;

        @include media(lg) {
          padding: 20px 30px 30px;
        }

        $icon-size-sm: 60px;
        $icon-size-lg: 88px;

        .icon {
          min-width: $icon-size-sm;
          width: $icon-size-sm;
          height: $icon-size-sm;
          background: $primary-color;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin-right: 10px;
          transition: background-color 0.1s 0.06s;

          img {
            width: $icon-size-sm - 20;
          }

          @include media(lg) {
            min-width: $icon-size-lg;
            width: $icon-size-lg;
            height: $icon-size-lg;
            margin-right: 20px;

            img {
              width: $icon-size-lg - 28;
            }
          }
        }

        p {
          text-transform: uppercase;
          font-weight: $fw-bold;
          line-height: 110%;
        }
      }

      &.green {
        &:after {
          background-color: $green-color;
        }

        .icon {
          background-color: $green-color;
        }
      }

      &.pink {
        &:after {
          background-color: $pink-color;
        }

        .icon {
          background-color: $pink-color;
        }
      }

      &.red {
        &:after {
          background-color: $red-color;
        }

        .icon {
          background-color: $red-color;
        }
      }


      &:hover, &.active {
        a {
          color: $light-color;
          transition: color 0.1s 0.12s;

          .icon {
            background: $light-color;
            box-shadow: 0 0 25px 1px rgba(255,255,255,0.15);
            transition: background-color 0.17s 0.13s, box-shadow 0.2s 0.16s;
          }
        }

        &:after {
          width: 100%;
          border-radius: $border-radius-large 0 0 $border-radius-large;
          transition: width 0.3s $easeInOutExpo, border-radius 0.1s 0.2s;
        }
      }
    }
  }
}
