@import "../imports/variables";
@import "../imports/colors";

@mixin background-image-retina($file, $type, $width: contain, $height: false) {
  background-image: url(($file + '.' + $type));

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url(($file + '@2x.' + $type));

    @if($height) {
      background-size: $width $height;
    }
    @else {
      background-size: $width;
    }
  }
}

@mixin media($key, $size: map-get($grid-breakpoints-full, $key)) {
  @media only screen and (min-width: #{$size}) {
    @content;
  }
}

@mixin media-below($key, $size: map-get($grid-breakpoints-full, $key)) {
  @media only screen and (max-width: #{$size - 1}) {
    @content;
  }
}

@mixin media-between($key-min, $key-max, $size-min: map-get($grid-breakpoints-full, $key-min), $size-max: map-get($grid-breakpoints-full, $key-max)) {
  @media only screen and (min-width: #{$size-min}) and (max-width: #{$size-max - 1}) {
    @content;
  }
}

@mixin color-scheme($prop: background, $prefix: '') {
  [class*=#{$prefix}] {
    @content;
  }

  @each $area, $color in $color-map {
    .#{$prefix + $area} {
      #{$prop}: $color;
    }
  }
}

@mixin color-scheme-embedded($prop: background, $prefix: '') {
  @each $area, $color in $color-map {
    &.#{$prefix + $area} {
      #{$prop}: $color;
    }
  }
}


@mixin font-size($size: $font-size-h1, $size-sm: $font-size-h1-sm, $size-md: $font-size-h1-md, $size-lg: $font-size-h1-lg, $size-xl: $font-size-h1-xl) {
  font-size: $size;

  @include media(sm) {
    font-size: $size-sm;
  }

  @include media(md) {
    font-size: $size-md;
  }

  @include media(lg) {
    font-size: $size-lg;
  }

  @include media(xl) {
    font-size: $size-xl;
  }
}


@mixin font-size-h1 {
  @include font-size();
}

@mixin font-size-h2 {
  @include font-size($font-size-h2, $font-size-h2-sm, $font-size-h2-md, $font-size-h2-lg, $font-size-h2-xl);
}

@mixin font-size-h3 {
  @include font-size($font-size-h3, $font-size-h3-sm, $font-size-h3-md, $font-size-h3-lg, $font-size-h3-xl);
}

@mixin card-style() {
  border: 1px solid $card-border-color;
  margin-bottom: 2rem;
  border-radius: $card-corner-radius;
  @include card-padding();
}

@mixin card-padding() {
  padding: $card-vert-padding $card-horz-padding;

  @include media(sm) {
    padding: $card-vert-padding-sm $card-horz-padding-sm;
  }

  @include media(lg) {
    padding: $card-vert-padding-sm $card-horz-padding-lg;
  }

  @include media(xl) {
    padding: $card-vert-padding-sm $card-horz-padding-xl;
  }
}

@mixin container() {
  width: $container-width;
  max-width: $container-max-width;
  position: relative;
  margin: 0 auto;

  @include media(md) {
    width: $container-width-md;
  }

  &.full {
    max-width: 100%;
    padding: 0;
  }

  &.sm {
    max-width: 575px;

    @include media(xl) {
      max-width: 700px;
    }
  }

  &.md {
    max-width: 680px;

    @include media(xl) {
      max-width: 800px;
    }
  }

  &.lg {
    max-width: 860px;
  }
}

@mixin container-fluid-padding() {
  padding: 0 4%;

  @include media(lg) {
    padding: 0 8%;
  }
}


@mixin extended-bg($file, $color-1, $color-2) {
  @include filter-gradient($color-1, $color-2, horizontal); // IE6-9 fallback on horizontal gradient
  @include background-image(linear-gradient(90deg, $color-1 20%, $color-2 80%));
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: url(#{$file}) no-repeat center center;
    background-size: auto 140%;

    @include media(lg) {
      background-size: auto 100%;
    }
  }
}

@mixin flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin placeholder($color: $grey-lighter, $opacity: 0.8) {
  &::placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin circle($width: 30px, $height: $width) {
  width: $width;
  height: $height;
  display: block;
  border-radius: 50%;
}

@mixin drop-shadow($size: 10px, $spread: 3px, $x: 0, $y: 3px, $color: rgba(0,0,0,0.05)) {
  position: relative;
  z-index: 2;
  box-shadow: $x $y $size $spread $color;
}

@mixin drop-shadow-inset($x: 0, $y: -4px, $blur: 10px, $spread: 3px) {
  position: relative;
  z-index: 2;
  box-shadow: $x $y $blur $spread rgba(0,0,0,0.05) inset;
}

@mixin triangle($side: 'right', $color: blue, $width: 30px, $height: $width) {
  border-top: ($height / 2) solid transparent;
  border-bottom: ($height / 2) solid transparent;
  border-left: ($width / 2) solid transparent;
  border-right: ($width / 2) solid transparent;
  border-#{$side}-color: $color;
  width: 0;
  height: 0;
}

@mixin absolute($left: 0, $top: 0, $width: 100%, $height: $width) {
  position: absolute;
  left: $left;
  top: $top;
  width: $width;
  height: $height;
}

@mixin size( $width, $height: $width ) {
  @if _is-size($height) {
    height: $height;
  }
  @else {
    @error "`#{$height}` is not a valid length for the `$height` argument " + "in the `size` mixin.";
  }

  @if _is-size($width) {
    width: $width;
  }
  @else {
    @error "`#{$width}` is not a valid length for the `$width` argument " + "in the `size` mixin.";
  }
}


/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

/* mixin for single line */
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin material-icon() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
