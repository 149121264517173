@import "../../imports/imports";

.sticky-wrapper {
  position: relative;

  .sticky-container {

    @include media(lg) {
      position: absolute;
    }

    &.sticky {
      @include media(lg) {
        position: fixed;
        top: 0;

        &.stick-to-bottom {
          top: auto;
          bottom: 0;
        }
      }
    }

    &.absolute {
      @include media(lg) {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }
  }
}
