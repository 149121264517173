@import "../imports/imports";

html,
button,
input,
select,
textarea {
  color: $dark-color;
}

body {
  font-size: $font-size-regular;

  @include media(lg) {
    font-size: $font-size-regular;
  }

  &,
  h1, h2, h3, h4, h5, h6,
  .mat-h1, .mat-h2,
  .mat-toolbar, .mat-card, .mat-list-item, .mat-menu-item,
  .mat-tab-label, .mat-tab-link, .mat-dialog-title,
  input, select, textarea, span, p, a, li, ul, .mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6,
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab, .mat-table,
  .mat-radio-button, .mat-radio-group, .mat-slide-toggle-content {
    font-family: $font-family-body;
    font-weight: $fw-regular;
    color: inherit;
    line-height: 150%;
  }

  h1, h2, h3, h4, h5, h6,
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
    color: inherit;
    font-family: $font-family-heading;
    line-height: 110%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    display: block;
    font-weight: $fw-bold;

    .sub-heading {
      display: block;
      font-weight: $fw-regular;
      color: $grey-dark-color;
    }
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 100%;
    @include font-size-h1();

    .sub-heading {
      @include font-size-h2();
    }
  }

  h2 {
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    @include font-size-h2();

    .sub-heading {
      @include font-size-h3();
    }
  }

  h3 {
    font-family: $font-family-body;
    font-weight: $fw-bold;
    line-height: 130%;
    @include font-size-h3();
  }

  h4 {
    font-size: $font-size-h4;
    font-family: $font-family-body;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 130%;
  }

  h5 {
    font-size: $font-size-h5;
    font-family: $font-family-body;
    font-weight: 400;
    text-transform: none;
    line-height: 130%;
    color: $dark-color;
    margin-bottom: 0.5rem;
  }


  p {
    color: inherit;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 170%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    display: block;
    padding: 30px 0;
    margin: 0;
    border: none;
    background: none;
    border-bottom: 1px solid $grey-color;
  }
}

.word-break-all {
  word-break: break-word;
  word-break: break-all;
}

@include color-scheme(color, 'text-');

.text-underline {
  text-decoration: underline !important;
}

.text-tiny {
  font-size: $font-size-tiny !important;
}

.text-smaller {
  font-size: $font-size-smaller !important;
  line-height: 100%;
}

.text-small {
  font-size: $font-size-small !important;
}

.text-large {
  font-size: $font-size-large !important;

  &.mat-icon {
    height: 40px;
    width: 40px;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-oversized {
  font-size: $font-size-oversized !important;
}

.bold {
  font-weight: $fw-bold !important;
}

.no-bold {
  font-weight: $fw-light !important;
}

.italic {
  font-style: italic !important;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.monospaced {
  font-family: $font-family-monospace !important;
}

.font-heading {
  font-family: $font-family-heading !important;
}

.unselectable {
  cursor: text !important;
  color: $grey-color;
  font-style: italic;
  font-size: inherit;
  line-height: 110%;
}

a:not([class]), .text-link {
  transition: color 0.2s $ease-out, border-color 0.2s $ease-out;
  color: $secondary-color;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  &.underlined {
    text-decoration: underline;
  }

  &:hover, &:focus, &.active {
    text-decoration: underline;

    &.underlined {
      text-decoration: none;
    }
  }

  &.text-dark {
    color: $text-color;

    &::after {
      border-bottom-color: $text-color;
    }
  }

  &.text-secondary {
    color: $secondary-color;

    &::after {
      border-bottom-color: $secondary-color;
    }
  }
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    .text-#{$key}-left {
      text-align: left !important;
    }

    .text-#{$key}-right {
      text-align: right !important;
    }

    .text-#{$key}-center {
      text-align: center !important;
    }
  }
}
