@import "../imports/imports";

.locale-selector {
  .static-links {
    display: none;
    align-items: center;
    justify-content: flex-start;
  }

  button, li {
    white-space: nowrap;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 0.875rem;

    @include media(lg) {
      font-size: 0.75em;
      margin-left: 10px;
    }

    .prefix-icon {
      margin-right: 5px;
    }

    .suffix-icon {
      margin-left: 5px;
    }
  }

  .header-wrapper.fixed & {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 2;
    background: $offlight-color;
    border-top: 1px solid $grey-lightest-color;
    padding: 10px 18px;

    .static-links {
      display: flex;
    }

    button, li {
      padding: 10px 20px;
    }
  }
}
