@import "../imports/imports";

.article-wrapper {
  padding-bottom: 50px;
}

.articles-container {
  .preview-text {
    margin-top: 10px;
  }
}

.article-lists {
  .article-link {
    text-decoration: none;
    font-size: 15px;
  }

  .featured-articles {
    padding-bottom: 10px;
    width: 100%;

    .article-link {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-decoration: none;
      margin: 0 auto 15px;
      max-width: none;
      transition: background-color 0.2s;
      padding: 10px;

      &:hover {
        background-color: $offlight-color;

        .title {
          text-decoration: underline;
        }

        .link {
          .mat-icon {
            right: -30px;
          }
        }
      }

      @include media(md) {
        flex-direction: row;
      }

      @include media(xxl) {
        padding: 15px;
      }

      .img {
        width: 100%;
        height: 143px;
        display: block;
        background: center no-repeat $grey-lightest-color;
        background-size: cover;
        margin: 0 0 12px;

        @include media(md) {
          min-width: 109px;
          width: 109px;
          height: 73px;
          margin: 0 20px 0 0;
        }
      }

      .title, .preview, .date, .link {
        font-size: 12px;
        display: block;
      }

      .title {
        font-size: 15px;
      }

      .preview, .date {
        margin-top: 8px;
      }

      .link {
        position: relative;
        display: inline-block;

        .mat-icon {
          position: absolute;
          right: -24px;
          top: 0px;
          font-size: 18px;
          transition: right 0.15s;
        }
      }
    }
  }

  .more-articles {
    padding: 10px;
    width: 100%;

    @include media(xl) {
      padding: 15px;
    }

    @include media(xxl) {
    }

    .article-link {
      line-height: 20px;
      padding-bottom: 10px;
      display: block;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
