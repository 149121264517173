@import "../imports/imports";

.home {
  .home-section {
    padding: 40px 0;
    position: relative;
    z-index: 1;

    @include media(lg) {
      padding: 60px 0;
    }

    &.shadowed {
      @include drop-shadow();
    }

    h1 {
      text-align: center;
    }

    h1, h2 {
      position: relative;
      margin-top: 0;
      margin-bottom: 2rem;
      padding-bottom: 2rem;

      @include media(lg) {
        margin-bottom: 1.5rem;
        padding-bottom: 0;
      }

      &:before, &:after {
        content: "";
        width: 80px;
        height: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 1;
        background: transparentize($green-color, 0.3);

        @include media(lg) {
          content: none;
          display: none;
        }
      }

      &:after {
        z-index: 2;
        transform: translateX(-100%);
        width: 40px;
        background: $green-color;
      }
    }

    .text-block {
      position: relative;

      @include media(lg) {
        padding-left: 30px;
        width: 70%;
      }

      &:before, &:after {
        width: 10px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: transparentize($green-color, 0.3);

        @include media(lg) {
          content: "";
        }
      }

      &:after {
        z-index: 2;
        height: 30px;
        background: $green-color;
      }
    }

    .text-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      text-align: center;

      @include media(lg) {
        justify-content: center;
        text-align: left;
        flex-direction: row;
      }

      .image-block {
        padding: 0;
        margin-bottom: 1.5rem;

        @include media(lg) {
          margin-bottom: 0;
          margin-right: 2.5rem;
        }

        img {

          @include media-below(lg) {
            width: 209px;
            margin: 0 auto;
          }
        }
      }
    }

    &.banner {
      background: $light-color;
      position: relative;
      z-index: 10;
      padding-bottom: 0;
    }

    &.section-1 {
      .inner {
        max-width: 900px;
        margin: 0 auto;

        .text-block {
          padding-bottom: 0;

          @include media(lg) {
            min-width: 63%;
            width: 63%;
            padding-right: 80px;
          }
        }
      }
    }
  }

  .pillar-pages-hero {
    text-align: center;
    background: url(($img-base + 'home/header-hero-bg.jpg')) no-repeat center center $offlight-color;
    background-size: cover;

    &:after {
      content: "";
      @include drop-shadow(6px, 6px);
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: -20px;
      left: 0;
    }

    .inner {
      max-width: 500px;
      margin: 0 auto 30px;

      h1 {
        font-size: 42px;
        margin-top: 1rem;
        margin-bottom: 1rem;

        span {
          font-size: 26px;
        }

        &:before, &:after {
          content: none;
          display: none;
        }
      }
    }

    .pillar-pages-list {
      margin: 0 -3%;
      flex-wrap: wrap;
      justify-content: center;

      @include media(lg) {
        margin-bottom: 10px;
      }

      li {
        flex-grow: 1;
        margin: 3%;
        position: relative;
        display: block;
        width: 90%;
        max-width: 44%;
        width: auto;
        flex-basis: 50%;

        @include media(xs) {
          max-width: 40%;
        }

        @include media(sm) {
          max-width: 180px;
        }

        @include media(md) {
          margin: 2%;
        }

        @include media(lg) {
          margin: 3%;
          max-width: 200px;
        }

        &:after {
          content: "";
          display: block;
          @include circle(70%);
          @include drop-shadow();
          position: absolute;
          z-index: initial;
          background: $light-color;
          height: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding-bottom: 70%;
          min-width: 130px;
          min-height: 130px;
          transition: transform 0.2s $easeOutExpo;

          @include media(xs) {
            padding-bottom: 100%;
            width: 100%;
          }
        }

        a {
          position: relative;
          z-index: 2;
          min-height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px 30px;

          @include media(xs) {
            min-height: 190px;
            min-width: 140px;
            padding: 20px 20px 10px;
          }

          @include media(md) {
            min-height: 180px;
            padding: 20px 30px 10px;
          }

          span {
            font-size: 16px;
            font-weight: $fw-bold;
            color: $text-color;
            line-height: 1.3rem;
            min-height: 40px;
            position: absolute;
            bottom: -25px;

            @include media(xs) {
              min-height: 32px;
              font-size: 13px;
              line-height: 1rem;
              position: relative;
              bottom: auto;
              margin-top: 10px;
            }
          }

          img {
            width: 80px;
          }
        }

        &:hover {
          &:after {
            transform: translate(-50%, -50%) scale(1.1);
            transition: transform 0.9s $easeOutExpo;
          }

          a {
            text-decoration: none !important;
          }
        }
      }
    }
  }

  .pillar-pages-content {
    background: $offlight-color;
    @include drop-shadow-inset();
    z-index: initial;
    overflow: hidden;

    $arrow-size: 70px;
    $arrow-size-lg: 100px;

    &:before, &:after {
      content: "";
      @include drop-shadow();
      background: $light-color;
      width: $arrow-size;
      height: $arrow-size;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: 4;

      @include media(lg) {
        width: $arrow-size-lg;
        height: $arrow-size-lg;
      }
    }

    &:after {
      box-shadow: none;
      width: (2 * $arrow-size);
      top: -$arrow-size;
      transform: translateX(-50%);
      z-index: 5;

      @include media(lg) {
        width: (2 * $arrow-size-lg);
        top: -$arrow-size-lg;
      }
    }

    .pillar-pages-list {
      flex-direction: column;
      padding: 120px 0 0;

      @include media(lg) {
        padding: 60px 0;
      }

      li {
        width: 100%;
        background: url(($img-base + 'home/pillar-card1-corner-bg.jpg')) no-repeat bottom -35px right $light-color;
        background-size: 200px auto;
        @include drop-shadow(5px, 1px);
        border-radius: $default-border-radius;
        padding: 120px 30px 40px;
        margin-bottom: 100px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include media(lg) {
          padding: 40px 60px 40px 130px;
          margin-bottom: 50px;
        }

        .icon {
          position: absolute;
          width: 175px;
          height: 175px;
          left: 50%;
          top: 0;
          transform: translate(-50%, -40%);
          background: $primary-color;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;

          @include media(lg) {
            left: 0;
            top: 50%;
            transform: translate(-40%, -50%);
          }

          @include media(xxl) {
            width: 207px;
            height: 207px;
            transform: translate(-60%, -50%);
          }

          &:before {
            @include circle(200px);
            background: $primary-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-60%, -50%);

            @include media(lg) {
              transform: translate(-60%, -50%);
              left: 0;
              top: 50%;
            }
          }
        }

        .inner {
          text-align: center;

          @include media(lg) {
            text-align: left;
          }

          h2 {
            margin-top: 1rem;
            padding-bottom: 0;
            margin-bottom: 1rem;

            &:before, &:after {
              content: none;
              display: none;
            }
          }

          .btn {
            margin: 1.5rem auto 0;
            max-width: 180px;

            @include media(lg) {
              margin: 0 0 0 auto;
            }
          }

          .summary {
            @include media(lg) {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        &.green {
          background-image: url(($img-base + 'home/pillar-card2-corner-bg.jpg'));

          .icon {
            background-color: $green-color;
          }

          .btn {
            background-color: $green-color;
          }
        }

        &.pink {
          background-image: url(($img-base + 'home/pillar-card3-corner-bg.jpg'));

          .icon {
            background-color: $pink-color;
          }

          .btn {
            background-color: $pink-color;
          }
        }

        &.red {
          background-image: url(($img-base + 'home/pillar-card4-corner-bg.jpg'));

          .icon {
            background-color: $red-color;
          }

          .btn {
            background-color: $red-color;
          }
        }
      }
    }
  }

  .articles-section {
    .inner {
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column-reverse;
      text-align: center;

      @include media(lg) {
        text-align: right;
        flex-direction: row;
      }

      .text-block {
        @include media(lg) {
          margin-bottom: 60px;
        }
      }

      .text-wrapper {
        display: block;

        @include media(lg) {
          padding-bottom: 80px;
          text-align: right;
          min-width: 49%;
          width: 49%;

          h2 {
            margin-bottom: 2.5rem;
          }
        }

        .image-block {
          padding: 0;
          margin-bottom: 1.5rem;
          display: block;

          img {
            width: 209px;
            margin: 0 auto;
          }
        }
      }

      .text-block {
        width: 100%;
        margin-bottom: 2rem;

        @include media(lg) {
          padding-right: 30px;
          padding-left: 0;
        }

        &:before, &:after {
          left: auto;
          right: 0;
        }
      }

      .image-block {
        padding-left: 80px;
      }
    }

    .articles-container {

      @include media(md) {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -.75%;
      }

      li {
        position: relative;
        display: block;
        background: $offlight-color;
        margin-bottom: 2.5rem;
        cursor: pointer;
        transform: translateZ(0);

        @include media(md) {
          width: 48.5%;
          max-width: 48.5%;
          flex-basis: 48.5%;
          margin: 0 .75% 30px;
        }

        @include media(lg) {
          width: 31.8333%;
          max-width: 31.8333%;
          flex-basis: 31.8333%;
        }

        .article-inner {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%;

          .image-block {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            height: 200px;
            min-height: 200px;

            @include media(sm) {
              height: 300px;
              min-height: 300px;
            }

            @include media(md) {
              height: 200px;
              min-height: 200px;
            }

            @include media(xl) {
              height: 275px;
              min-height: 275px;
            }

            .image-content {
              background: center center no-repeat;
              background-size: cover;
              width: 100%;
              height: 100%;
            }
          }
        }

        .inner-content {
          padding: 25px 20px;
          border: 1px solid $grey-lightest-color;
          border-top: 0;
          height: 100%;

          @include media(lg) {
            padding: 30px 30px 40px;
          }
        }

        h3 {
          margin-top: 0;
        }

        .body-preview {
          margin-bottom: 1.5rem;
          color: $grey-medium-color;
        }
      }
    }
  }
}

.callout {
  margin: 30px auto 40px;
  border-radius: $default-border-radius;
  position: relative;
  background: $purple-color;
  padding: 30px;
  color: $light-color;
  text-align: center;

  @include media(lg) {
    margin: 0 auto 80px;
    padding: 55px;
  }

  p {
    @include font-size-h3();
    line-height: 130%;
    margin-bottom: 17px;

    @include media(lg) {
      margin-bottom: 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  > * {
    position: relative;
    z-index: 10;
  }

  $arrow-size: 50px;

  &:before, &:after {
    content: "";
    background: $purple-color;
    width: $arrow-size;
    height: $arrow-size;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    bottom: -($arrow-size - 10px);
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 4;
  }

  .image-left, .image-right {
    background: url(($img-base + 'home/pillar-card1-corner-bg_transparent.png')) no-repeat left top transparent;
    background-size: cover;
    position: absolute;
    width: 140px;
    height: 140px;
  }

  .image-right {
    right: 0;
    bottom: 0;
  }

  .image-left {
    left: 0;
    top: 0;
    transform: rotate(180deg);
  }


  &:after {
    width: (2 * $arrow-size);
    bottom: 0;
    transform: translateX(-50%);
    z-index: 5;
  }

  &.purple {
  }
}
