@import "../imports/imports";

[hidden] {
  display: none !important;
  visibility: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

.sr-only {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    .d-#{$key}-none {
      display: none !important;
    }

    .d-#{$key}-block {
      display: block !important;
    }

    .d-#{$key}-flex {
      display: flex !important;
    }

    .d-#{$key}-inline-block {
      display: inline-block !important;
    }
  }
}
