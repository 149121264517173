@import "../imports/imports";

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    .overflow-#{$key}-hidden {
      overflow: hidden !important;
    }

    .overflow-#{$key}-auto {
      overflow: auto !important;
    }
  }
}
