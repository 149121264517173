@import "../../imports/imports";

content-loader {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
}
